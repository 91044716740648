.wip {
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .logo-svg {
      width: 200px;
    }

    p {
      font-size: 2em;
    }

    @media only screen and (min-width: $sm-tablet) {
      .logo-svg {
        width: 400px;
      }

      p {
        font-size: 3em;
      }
    }

    @media only screen and (min-width: $tablet-laptop) {
      .logo-svg {
        width: 500px;
      }

      p {
        font-size: 4em;
      }
    }

    // // Desktop Query
    // @media only screen and (min-width: $desktop) {
    // }
  }
}